function Start(props) {


  const list = [
    {Length: 6000, Width:1200, Height: 10, Qty: 1},
    {Length: 6000, Width:1350, Height: 10, Qty: 2},
    {Length: 3000, Width:1200, Height: 10, Qty: 4},
    {Length: 3000, Width:1350, Height: 10, Qty: 1},
    {Length: 5400, Width:1350, Height: 10, Qty: 5},
    {Length: 4200, Width:1200, Height: 10, Qty: 3},
    {Length: 4200, Width:1350, Height: 10, Qty: 3}
  ]

  const result = list.sort(function(a,b){
    if(a.Width > b.Width) return -1;
    return 0;
  });

  const result1 = result.sort(function(a,b){
    if(a.Length < b.Length) return -1;
    return 0;
  });
  
  return (
    <> 
    
    <br />
      
      {result1.map((item, index) => (
        <div class="row" key={index}>      
          <div class="col-4" style={{"background":"lightgrey"}}> {item.Length} {item.Width} </div>
        </div>
      ))}
    
    </>
  );
}

export default Start;
