import React from "react";

export default class ListProduct extends React.Component {
    constructor(props) {
      super(props);
  
      this.handleChange = this.handleChange.bind(this);
      this.changeValue = this.changeValue.bind(this);
    }
  
    handleChange(event) {
        // create obj to send to main.js to update product

        const UpdatedProductAndIndex = {
            UpdatedProduct: {
                ...this.props.product, 
                Qty: event.target.value
            }
        }
        this.props.onQtyChange(UpdatedProductAndIndex);
    }

    changeValue(event) {
        const UpdatedProductAndIndex = {
            UpdatedProduct: {
                ...this.props.product, 
                Qty: Number(this.props.product.Qty) + Number(event.target.attributes.changevaluemath.value)
            }
        }
        this.props.onQtyChange(UpdatedProductAndIndex);
    }
  
    render() {
        return (
            <div className="row mt-3">
                <div className="col-12 col-sm-5">
                    <h4 class="fw-bold mb-0">
                        {this.props.product.Title}
                    </h4>
                </div>
                <div className="col-12 col-sm-7">
                    <div className="row g-0">
                        <div className="col-4 text-center">
                            <button type="button" class="btn btn-primary m-1" onClick={this.changeValue} changevaluemath="-10" >- 10</button>
                            <button type="button" class="btn btn-primary m-1" onClick={this.changeValue} changevaluemath="-1" >-</button>
                        </div>
                        <div className="col-4">
                            <input className="form-control m-1" type="text" value={this.props.product.Qty} onChange={this.handleChange} />
                        </div>
                        <div className="col-4 text-center">
                            <button type="button" class="btn btn-primary m-1" onClick={this.changeValue} changevaluemath="1" >+</button>
                            <button type="button" class="btn btn-primary m-1" onClick={this.changeValue} changevaluemath="10" >+ 10</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
