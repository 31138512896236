import React from "react";

import ListProduct from "./ListProduct";
import SearchBar from "./SearchBar"


export default class Calculator extends React.Component {
  constructor (props){
    super(props)

    this.state = {
      AllProducts: [
        {
          Title: "6000 X 1200 CEILING 10MM SHEETROCK",
          Weight: 42.48,
          MetersSquared: 7.2,
          Qty: 0
        },{
          Title: "6000 X 1350 CEILING 10MM SHEETROCK",
          Weight: 47.79,
          MetersSquared: 8.1,
          Qty: 0
        },{
          Title: "6000 X 1200 WALL 10MM SHEETROCK",
          Weight: 38.88,
          MetersSquared: 7.2,
          Qty: 0
        },{
          Title: "6000 X 1350 WALL 10MM SHEETROCK",
          Weight: 8.1,
          MetersSquared: 43.74,
          Qty: 0
        },{
          Title: "5400 X 1350 WET AREA 10MM",
          Weight: 53.946,
          MetersSquared: 7.29,
          Qty: 0
        },{
          Title: "4800 X 1200 CEILING 10MM SHEETROCK",
          Weight: 33.984,
          MetersSquared: 5.76,
          Qty: 0
        },{
          Title: "4800 X 1350 CEILING 10MM SHEETROCK",
          Weight: 38.232,
          MetersSquared: 6.48,
          Qty: 0
        },{
          Title: "4800 X 1200 WALL 10MM SHEETROCK",
          Weight: 31.104,
          MetersSquared: 5.76,
          Qty: 0
        },{
          Title: "4800 X 1350 WALL 10MM SHEETROCK",
          Weight: 6.48,
          MetersSquared: 34.992,
          Qty: 0
        },{
          Title: "4200 X 1200 CEILING 10MM SHEETROCK",
          Weight: 29.736,
          MetersSquared: 5.04,
          Qty: 0
        },{
          Title: "4200 X 1200 WALL 10MM SHEETROCK",
          Weight: 27.216,
          MetersSquared: 5.04,
          Qty: 0
        },{
          Title: "4200 X 1350 WALL 10MM SHEETROCK",
          Weight: 30.618,
          MetersSquared: 5.67,
          Qty: 0
        },{
          Title: "4200 X 1200 WET AREA 10MM",
          Weight: 37.296,
          MetersSquared: 5.04,
          Qty: 0
        },{
          Title: "3600 X 1200 CEILING 10MM SHEETROCK",
          Weight: 25.488,
          MetersSquared: 4.32,
          Qty: 0
        },{
          Title: "3600 X 1350 CEILING 10MM SHEETROCK",
          Weight: 28.674,
          MetersSquared: 4.86,
          Qty: 0
        },{
          Title: "3600 X 1200 WALL 10MM SHEETROCK",
          Weight: 23.328,
          MetersSquared: 4.32,
          Qty: 0
        },{
          Title: "3600 X 1350 WALL 10MM SHEETROCK",
          Weight: 26.244,
          MetersSquared: 4.86,
          Qty: 0
        },{
          Title: "3600 X 1200 WET AREA 10MM",
          Weight: 31.968,
          MetersSquared: 4.32,
          Qty: 0
        },{
          Title: "3600 X 1350 WET AREA 10MM",
          Weight: 35.964,
          MetersSquared: 4.86,
          Qty: 0
        },{
          Title: "3000 X 1200 WALL 10MM SHEETROCK",
          Weight: 19.44,
          MetersSquared: 3.6,
          Qty: 0
        },{
          Title: "3000 X 1350 WALL 10MM SHEETROCK",
          Weight: 21.87,
          MetersSquared: 4.05,
          Qty: 0
        },{
          Title: "3000 X 1200 WET AREA 10MM",
          Weight: 26.64,
          MetersSquared: 3.6,
          Qty: 0
        },{
          Title: "2400 X 1200 WALL 10MM SHEETROCK",
          Weight: 15.552,
          MetersSquared: 2.88,
          Qty: 0
        },{
          Title: "2400 X 1200 WET AREA 10MM",
          Weight: 21.312,
          MetersSquared: 2.88,
          Qty: 0
        }
      ],
      ProductsToDisplay: [],
      TotalWeight: 0,
      SearchStr: ""
    }
    
    
    this.handleQtyChange = this.handleQtyChange.bind(this);
    this.updateOrderTotals = this.updateOrderTotals.bind(this);
    this.resetOrderTotals = this.resetOrderTotals.bind(this);
    this.updateProductsToDisplay = this.updateProductsToDisplay.bind(this);
  }

  handleQtyChange(props) {
    let CopyOfAllProducts = this.state.AllProducts;

    CopyOfAllProducts.find(item => item.Title == props.UpdatedProduct.Title).Qty = props.UpdatedProduct.Qty

    // update state
    this.setState({
      AllProducts: [
        ...CopyOfAllProducts
      ]
    });

    this.updateOrderTotals()
    this.updateProductsToDisplay()
  }

  updateOrderTotals() {
    let TotalWeight = 0;

    this.state.AllProducts.forEach(item => {
      TotalWeight = TotalWeight + (item.Weight * item.Qty)
    });

    this.setState({
      TotalWeight: TotalWeight
    });
    
  }

  resetOrderTotals() {
    this.state.AllProducts.forEach(item => {
      item.Qty = 0
    });

    this.setState({
      TotalWeight: 0
    });
    
  }

  updateProductsToDisplay(providedSearchText) {  
    let setProductInputSuggestions = [];

    let SearchStr = "";
    if (providedSearchText) { // if Search text is provided when calling function, use that text
      SearchStr = providedSearchText
    } else { // otherwise, use one from state ( refresh )
      SearchStr = this.state.SearchStr
    }


    if (SearchStr === "") {
      setProductInputSuggestions = [];
    } else {
      let buildSearchRegex = "";
      
      SearchStr.split(" ").forEach((word) => {
        buildSearchRegex += "(" + word + ").*";
      })

      var searchRegex = new RegExp(buildSearchRegex, "gmi")

      this.state.AllProducts.forEach((Product) => {
          if(Product.Title.match(searchRegex)) {
            setProductInputSuggestions = [...setProductInputSuggestions, Product];
          }
      })
    }


    this.setState({
      ProductsToDisplay: setProductInputSuggestions
    });

  }

  render() {
    return (
      <> 

        <div className="row" style={{"top":"0px","position":"fixed","background":"#ffffffeb","width":"100vw","padding":"10px"}}>
          <div className="col-12">
            <input type="text" class="form-control" placeholder="Search Product.." autoComplete="off" onChange={(event) => {this.setState({SearchStr: event.target.value.replace(/[#-.]|[[-^]|[?|{}]/g, '\\$&')}); this.updateProductsToDisplay(event.target.value.replace(/[#-.]|[[-^]|[?|{}]/g, '\\$&')) }} />
          </div>
        </div>

        <div style={{"height":"45px"}}></div>


        {
        this.state.ProductsToDisplay.length == 0 
        ? this.state.SearchStr.length == 0 
          ? this.state.AllProducts.map((o, i) => (
              <>
                <ListProduct key={i} product={o} onQtyChange={this.handleQtyChange} />
              </>
            ))
          : <>
              <div className="row mt-3">
                <div className="col-12">No product found, search again.</div>
              </div>
            </>
        : this.state.ProductsToDisplay.map((o, i) => (
            <>
              <ListProduct key={i} product={o} onQtyChange={this.handleQtyChange} />
            </>
          ))
        }

        <div className="row justify-content-between" style={{"bottom":"0px","position":"fixed","background":"#ffffffeb","width":"100vw","padding":"10px"}}>
          <div className="col">
            <h5 style={{"margin":"0px"}}>TotalWeight: {this.state.TotalWeight.toFixed(2)} Kgs</h5>
          </div>
          <div className="col-2">
            <button type="button" class="btn btn-danger" onClick={this.resetOrderTotals} >Reset</button>
          </div>
        </div>

        <div style={{"height":"50px"}}></div>
      </>
    );
  }
}